import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/mailbox/mailbox.vue"),
    name: "Inicio",
  },

  // {
  //   path: "/legals",
  //   component: () => import("../views/legals/"),
  //   children: [
  //     {
  //       path: "/aviso-de-privacidad",
  //       name: "Aviso de Privacidad",
  //       component: () => import("../views/legals/aviso-de-privacidad.vue"),
  //     },
  //     {
  //       path: "/terminos-y-condiciones",
  //       name: "Términos y condiciones",
  //       component: () => import("../views/legals/terminos-y-condiciones.vue"),
  //     },
  //     {
  //       path: "/colaboracion-con-la-justicia",
  //       name: "Colaboración con la justicia",
  //       component: () =>
  //         import("../views/legals/colaboracion-con-la-justicia.vue"),
  //     },
  //     {
  //       path: "/codigo-de-etica",
  //       name: "Código de ética",
  //       component: () => import("../views/legals/codigo-de-etica.vue"),
  //     },
  //   ],
  // },

  // {
  //   path: "/mailbox",
  //   component: () => import("../views/mailbox/"),
  //   children: [
  //     {
  //       path: "/buzon",
  //       name: "Buzón",
  //       component: () => import("../views/mailbox/mailbox.vue"),
  //     },
  //   ],
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   return savedPosition || { top: 0 };
  // },
});

export default router;
