<template>
  <footer class="page-footer bg-image">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 py-3">
          <p>
            Para la recepción de consultas, quejas o sugerencias te atendemos
            las 24 horas del día, los 365 días del año vía telefónica
            <a href="tel:8005782323">800 578 2323</a> o al correo
            <a href="mailto:contacto@beetelecom.mx">contacto@beetelecom.mx</a>
            o ingresa a nuestro
            <a href="https://buzon.beetelecom.mx">buzón digital</a>
          </p>

          <p>
            En cumplimiento a lo establecido en los lineamientos de calidad en
            el servicio fijo, se hace del conocimiento del público en general
            que pueden consultar en los centros de atención y/o en nuestro
            portal de internet, la información relacionada con los sistemas de
            atención a usuarios finales.
          </p>
        </div>

        <div class="col-lg-5 col-12 d-flex justify-content-end">
          <ul class="footer-menu">
            <li>
              <router-link to="/aviso-de-privacidad" class="btn">
                <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                Aviso de Privacidad
              </router-link>
            </li>

            <li>
              <router-link to="/terminos-y-condiciones" class="btn">
                <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                Términos y Condiciones
              </router-link>
            </li>

            <li>
              <b-button
                variant="link"
                class="btn"
                v-on:click="publicInformation"
              >
                <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                Información Pública
              </b-button>
            </li>

            <li>
              <router-link to="/colaboracion-con-la-justicia" class="btn">
                <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                Colaboración con la Justicia
              </router-link>
            </li>

            <li>
              <b-button
                variant="link"
                class="btn"
                v-on:click="charterMinimumRights"
              >
                <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                Carta de Derechos Mínimos
              </b-button>
            </li>

            <li>
              <router-link to="/codigo-de-etica" class="btn">
                <b-icon icon="chevron-right" aria-hidden="true"></b-icon>
                Código de Ética
              </router-link>
            </li>
          </ul>
        </div>

        <div class="col-lg-12 text-center">
          <p class="title">
            © 2022 BEETELCO S.A. DE C.V. TODOS LOS DERECHOS RESERVADOS
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",

  methods: {
    publicInformation: function (event) {
      event.preventDefault();

      window.open(
        "https://beetelecom.mx/doctos/Concesion-BeeTelecom.pdf",
        "_blank",
        "fullscreen=yes"
      );

      return false;
    },

    charterMinimumRights: function (event) {
      event.preventDefault();

      window.open(
        "https://beetelecom.mx/doctos/carta-de-derechos-minimos.pdf",
        "_blank",
        "fullscreen=yes"
      );

      return false;
    },
  },
};
</script>

<style scoped>
.page-footer {
  position: relative;
  display: block;
  padding-top: 80px;
  padding-bottom: 16px;
  /* background-color: #FECD00; */
  background-color: rgba(0, 0, 0, 0.1);
  background-image: url(../assets/img/bg/world_pattern.svg);
}

.page-footer .title {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
}

.page-footer p {
  color: #fecd00;
}
.page-footer p a {
  font-weight: 600;
  text-decoration: none;
  color: #fecd00;
}

.footer-menu {
  position: relative;
  padding-left: 0;
  list-style: none;
}

.footer-menu li a,
.footer-menu li button {
  color: #ffffff;
}

.footer-menu a,
.footer-menu button,
.footer-link {
  color: #898798;
  text-decoration: none;
}

.footer-menu a:hover,
.footer-menu button:hover,
.footer-link:hover {
  color: #ffffff;
  text-decoration: none;
}

.social-media-button a,
.social-media-button button {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 44px;

  border-radius: 50%;
  background-color: #fecd00;
  color: #000000;
  transition: all 0.2s linear;

  padding: 0px;
  margin: 0px;
}

.social-media-button a svg {
  text-align: center;
  align-content: center;
}

.social-media-button a:hover {
  background-color: #000000;
  color: #fecd00;
  text-decoration: none;
  transform: rotate(360deg);
}
</style>
