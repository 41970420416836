<template>
  <div id="app">
    <Preloader />

    <router-view />

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Preloader from "@/components/preloader.vue";
import Footer from "@/components/footer.vue";

export default {
  name: "App",

  components: {
    Preloader,
    Footer,
  },

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped></style>
